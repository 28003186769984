// colors
$color-blue: #009ff5;
$color-red: #eb5757;
$color-green: #2abbaa;
$color-yellow: #ffdb29;
$color-orange: #e67e22;

// Semantic colors
$color-success: #05ce91;
$color-warning: #ffa800;
$color-error: #ff3152;
$color-no-sensor: #424242;
$color-info: #76b9ff;
$color-new: #ff8dff;

// Full screen value colors
$color-good: #4caf50;
$color-medium: #ffd45e;
$color-bad: #d93b23;

// leave icon colors
$color-leave-good: #4caf50;
$color-leave-medium: #ffd45e;
$color-leave-bad: #d93b23;

// primary, and gray scale
$color-primary: #ed1c24;
$color-primary-light: #fbd2d3;
$color-primary-dark: #ab0f14;

$color-secundary: #ef6c00;
$color-secundary-light: #ff9d3f;
$color-secundary-dark: #b53d00;

$color-terciary: #4caf50;
$color-terciary-light: #80e27e;
$color-terciary-dark: #087f23;

$color-black: rgba(66, 66, 66, 1);
$color-dark-gray: rgba(66, 66, 66, 0.8);
$color-light-gray: rgba(66, 66, 66, 0.6);
$color-extra-light-gray: rgba(66, 66, 66, 0.4);
$color-extra-extra-light-gray: rgba(66, 66, 66, 0.2);
$color-white: #ffffff;

// component colors
$color-text: #424242;
$color-placeholder: #9d9d9d;
$color-border: #dddddd;
$color-light-bg: #f9f9f9;
$color-bg-button: #f4f4f4;
$color-subtext: #666;
$color-link: #ff0008;
$color-body-bg: #fdfdfd;

$color-primary-hover: darken($color-primary, 3%);
$color-primary-dark-hover: darken($color-primary-dark, 3%);
$color-error-hover: darken($color-red, 3%);
$color-warning-hover: $color-error-hover;

// Components
$view-max-width: 1110px;
$auth-view-max-width: 400px;
$header-height: 55px;
$aux-header-height: 30px;
$dashboard-tab-height: 32px;
$dashboard-tab-panel-tabs-height: 56px;
$dashboard-tab-panel-height: 400px;

$dashboard-outdoorQuality-tab-panel-height: 633px;

// Breakpoints
$breakpoints: (
  phone: 320px,
  tablet: 769px,
  desktop: 1024px,
  medium: 768px,
  small: 500px,
  mini: 350px
);

// Spacing
$space-xxs: 2px;
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 32px;
$space-xl: 64px;
$space-xxl: 128px;

:export {
  // colors
  colorBlue: $color-blue;
  colorRed: $color-red;
  colorGreen: $color-green;
  colorYellow: $color-yellow;
  colorOrange: $color-orange;

  // info colors
  colorSuccess: $color-success;
  colorWarning: $color-warning;
  colorError: $color-error;
  colorNoSensor: $color-no-sensor;

  // Full screen value colors
  colorGood: $color-good;
  colorMedium: $color-medium;
  colorBad: $color-bad;

  // leave icon colors
  colorLeaveGood: $color-leave-good;
  colorLeaveMedium: $color-leave-medium;
  colorLeaveBad: $color-leave-bad;

  // primary, and gray scale
  colorPrimary: $color-primary;
  colorPrimaryLight: $color-primary-light;
  colorPrimaryDark: $color-primary-dark;
  colorBlack: $color-black;
  colorDarkGray: $color-dark-gray;
  colorLightGray: $color-light-gray;
  colorExtraLightGray: $color-extra-light-gray;
  colorExtraExtraLightGray: $color-extra-extra-light-gray;
  colorWhite: $color-white;
}
