@import '~styles/base.module.scss';

.modalContainer {
  max-width: 500px !important;
  width: 500px !important;

  min-height: 70%;
  max-height: 100% !important;
  margin: auto;
  margin-top: 0;
  box-shadow: 2px;
  padding: 1px 4px;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: calc($header-height + 35px) !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
  }
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;
  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
}

.fullScreenHeader {
  padding-top: 20px;
  width: 100%;
  margin-bottom: 50px;
}

.fullScreenTitle {
  width: 100%;
  padding-bottom: 1%;
}

.fullScreenSubtitle {
  width: 100%;
  padding-bottom: 1%;
}

.fullScreenContent {
  padding-top: 30px;
}

.devicesDropdown {
  width: 80%;
}

.deviceCard {
  width: 100%;
}

.deviceInfo {
  display: flex;
}

.deviceName {
  width: 70%;
}

.deviceType {
  width: 30%;
}

.customizingViewDisabled {
  opacity: 0.1;
}

.parametersContent {
  margin-top: 10%;
  padding: 2% 0% 0% 2%;
}

.viewTypeContent {
  margin-top: 10%;
  padding: 2% 0% 0% 2%;
}

/*NAV HEADERS*/
.nav {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding-inline: 10px;
  height: 7%;
  padding-bottom: 10%;
  padding-top: 10px;
}

.subheaderDevicesSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}

.checkedLigthsWrapper {
  display: flex;
  font-family: bold !important;
  align-items: center;
  justify-content: space-between;
}

.deviceLightsInfoLabel {
  font-size: 12px !important;
}

.subheaderDevices {
  padding: 4px;
  font-size: 12px;
}
.subheaderParamsSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}

.subheaderParams {
  padding: 4px;
  font-size: 12px;
}
.subheaderPrivacySelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}
.subheaderValues {
  padding: 4px;
  font-size: 12px;
}
.subheaderValuesSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}

.subheaderPrivacy {
  padding: 4px;
  font-size: 12px;
}

.subInfo {
  color: $color-dark-gray;
  margin-bottom: $space-m !important;
}

.input {
  margin-top: 1.05em !important;
  margin-bottom: 0.25em !important;
  text-align: start !important;
  width: 100%;
  padding: 0.3%;
  input {
    padding: 18.5px 14px !important;
  }
  align-items: center;
  color: $color-primary !important;
}
.inputField {
  max-width: 50px;
  display: flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-ms-autofill,
input:-ms-autofill:hover,
input:-ms-autofill:focus,
textarea:-ms-autofill,
textarea:-ms-autofill:hover,
textarea:-ms-autofill:focus,
select:-ms-autofill,
select:-ms-autofill:hover,
select:-ms-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-o-autofill,
input:-o-autofill:hover,
input:-o-autofill:focus,
textarea:-o-autofill,
textarea:-o-autofill:hover,
textarea:-o-autofill:focus,
select:-o-autofill,
select:-o-autofill:hover,
select:-o-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
textarea:-moz-autofill,
textarea:-moz-autofill:hover,
textarea:-moz-autofill:focus,
select:-moz-autofill,
select:-moz-autofill:hover,
select:-moz-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.MuiOutlinedInput-notchedOutline {
  border-color: $color-border;
}
.input label span {
  display: none;
}
.selectDevicesHelper {
  font-size: 13px;
  margin-bottom: $space-s;
  margin-top: $space-s;
}

.noGroupWrapper {
  margin-bottom: $space-l;
}

.devicesListPaper {
  height: 350px !important;
  max-height: 350px !important;
  overflow: auto;
  margin-bottom: $space-s;
}

.devicesList {
  max-height: 100%;
  overflow: auto;
}

.listItemText {
  flex: 0 0 auto !important;

  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}
.groupOptionsButton {
  display: flex;
  flex-flow: row nowrap;
  float: right;
  border-radius: 38px;
  border-color: transparent;

  background-color: $color-white;
  padding: $space-s $space-m;
  margin-bottom: $space-m;
  margin-right: 8px;
  &:hover {
    background-color: $color-extra-extra-light-gray;
    opacity: 80%;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
  @include media('<small') {
    font-size: 0;
  }
}
.micaTypeItemText {
  flex: 0 0 auto !important;
  margin-left: $space-l;
  span {
    font-size: 12px;
    font-weight: 500 !important;
  }
}
ul[role='menu'] {
  padding: 0 !important;
}

.micaItem {
  flex: 0 0 auto !important;
  margin-left: $space-xl;
}
.navItem {
  margin-left: $space-xs;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  color: $color-primary;
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.errorWrapper {
  margin-top: $space-m !important;
  margin-bottom: $space-m !important;
}

/*BUTTONS*/

/*BUTTONS*/
.buttons {
  margin-top: 45% !important;
  @include media('<small') {
    margin-top: 0 !important;
  }
}

.buttonWrapper {
  text-align: center;
  margin: 0;
  font-weight: 600px !important;
}

.buttonSiguiente {
  margin-top: 30px;
  border-radius: 30px;
  background-color: $color-primary;
  color: $color-white;
  box-shadow: 3px 4px 3px rgb(168, 167, 167) !important;
  align-items: center;
  margin-bottom: 0;
  &:hover {
    background-color: #006db3;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
  .arrow {
    margin-left: 0.35em !important;
    margin-right: 0 !important;
  }
}

.buttonAdd {
  margin-top: 30px !important;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }
  &:disabled {
    background-color: $color-extra-extra-light-gray !important;
    color: $color-extra-light-gray !important;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
  .buttonAdd .arrow {
    margin-left: 0.35em !important;
    margin-right: 0 !important;
  }
}

.buttonCancelar {
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  font-weight: bolder !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;
  &:hover {
    background-color: rgb(198, 230, 248);
  }
  .arrow {
    margin-right: 0.35em;
    margin-left: 0;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.visualizeQRWrapper {
  display: flex;
  font-family: bold !important;
  align-items: center;
  justify-content: space-between;
}

.infoPublicWrapper {
  padding-top: 4% !important;
  display: flex;
  padding-bottom: 10%;
  font-size: 12px !important;

  @include media('<small') {
    padding-bottom: 0 !important;
  }
}

.infoUpdateWrapper {
  display: flex;
  font-size: 12px !important;

  @include media('<small') {
    padding-bottom: 0 !important;
  }
}

.iconPublic {
  padding-right: 5%;
}
.menuItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Roboto';
  padding-top: 7px;
  padding-bottom: 7px;

  transition: color 0.3s ease;
  cursor: pointer;
  color: $color-text;
  width: 100%;
  &:hover {
    background-color: rgba(0, 155, 229, 0.2) !important;
  }

  &:active {
    background-color: rgba(0, 155, 229, 0.5) !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.menuItemFocus {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Roboto';
  padding-top: 7px;
  padding-bottom: 7px;

  transition: color 0.3s ease;
  cursor: pointer;
  color: $color-text;
  width: 100%;
  background-color: #fbd2d3;

  &:hover {
    background-color: #fbd2d3 !important;
  }
}

.wrapperDefaultValues {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkboxList {
  padding-bottom: 50% !important;
}

.checkboxWrapper {
  height: 435px !important;
  max-height: 435px !important;
  overflow: auto;
  padding: 7%;
  padding-top: 2%;
  flex-flow: column wrap;
}
.paramWrapper {
  height: 435px !important;
  max-height: 435px !important;
  padding: 7% 11% 0 0;
  flex-flow: column wrap;
}
.checkboxFormDiv {
  align-items: center;
  justify-content: space-between;
}
.dataInput {
  justify-content: flex-end;
}

.unitsParams {
  text-align: end !important;
  font-size: 0.75rem !important;
  font-family: sans-serif !important;
  .MuiTypography-root {
    font-size: 0.75rem !important;
    font-family: sans-serif !important;
  }
  .MuiInputAdornment-positionEnd {
    font-size: 0.75rem !important;
    font-family: sans-serif !important;
  }
}

.MuiTypography-root {
  font-size: 0.75rem !important;
  text-align: end !important;
  font-family: sans-serif !important;
}

MuiInputAdornment-positionEnd {
  font-size: 0.75rem !important;
  font-family: sans-serif !important;
}
.inputUnit {
  display: inline-block;
  font-weight: 450;
  width: 100%;
  text-align: right !important;

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.paramFormDiv {
  padding-top: 0;
  display: flex;
  align-items: center;
  max-height: 45px !important;
  padding: none;
}
.wrapperDataInfo {
  display: flex !important;
  align-content: baseline !important;
  align-items: center;
  margin-left: auto;
  justify-content: space-between;
}
.checkboxForm {
  padding-top: 15px;
  display: flex;
  align-items: center;
  max-height: 45px !important;
  justify-content: space-between;
  padding: none;
  margin: 0;
  span {
    font-size: 14px !important;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkedBox {
  svg {
    color: #ff3152 !important;
    margin: 0;
    padding: 0;
  }
}

.checkbox {
  //recuadro del check
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: $color-white !important;
  text-align: center;
  svg {
    color: black;
  }

  .inputParam {
    background-color: rebeccapurple;
    text-align: end !important;
    font-weight: 800px !important;
    &:hover {
      background-color: $color-primary !important;
    }
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #ff3152 !important;
    background-color: $color-primary !important;
  }
}

.checkboxIcon {
  //el tick
  background-color: $color-white !important;
  color: $color-primary !important;
  border-radius: 2px;
}

.hasError {
  border-color: $color-error !important;
}

.checkboxIcon {
  background-color: $color-primary;
  color: $color-white !important;
  border-radius: 4px;
}

.radioButtonRow {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 5% !important;
}

.radioButton {
  color: $color-primary !important;
  width: 15%;
}

.radioButtonLabel {
  width: 70%;
  border-radius: 65px !important;
  border: 2px solid $color-primary !important;
  padding: 2% 0% 2% 2% !important;
}

.openScreenButton {
  color: $color-white !important;
  background-color: $color-primary !important;
  border-radius: 38px;
  float: right;
  margin-top: 5%;
}

.error {
  margin-top: $space-s !important;
  color: $color-red !important;
  margin-bottom: $space-s !important;
}

.wrapperInfoButton {
  display: flex;
  flex-flow: row nowrap;
  float: right;
  border-radius: 38px;
  border-color: transparent;
  padding-left: 10px;
  text-transform: unset !important;
  background-color: $color-white;
  color: $color-black;
  &:hover {
    background-color: $color-extra-extra-light-gray;
    opacity: 80%;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
  @include media('<small') {
    font-size: 0;
  }
}
.infoButton {
  padding: 0 !important;
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}
.dialogInfo {
  border-radius: 50px;
}
.caja {
  background-color: $color-white;
  border-radius: 30px;
  z-index: 7000 !important;
  position: relative;
  left: 360px;
  bottom: 70px;
  padding: 5%;
  box-shadow: 0 0 5px grey !important;
}

.wrap {
  max-height: 45px !important;
}

.inputUnity {
  max-width: 2px;
  padding: 7px;
  margin-left: auto;
  font-size: '0.85rem';
  color: grey;
}

.errorMessage {
  color: $color-error !important;
  margin: 10px 0 0 0 !important;
  background-color: rgba($color-error, 0.2) !important;
}
