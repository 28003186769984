@import '~styles/base.module.scss';

.content {
  height: 100% !important;
  padding: $space-l !important;
  width: 70%;
  margin: 0 auto;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: calc($header-height + 35px) !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
  }
}

.centerConatiner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  text-align: center;
  max-width: 512px !important;
  width: 512px !important;

  margin: auto;
  box-shadow: 2px;
  z-index: 2000;
  padding: $space-l !important;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: visible !important;

  @include media('<small') {
    position: center;
    padding: $space-l !important;
    width: 100% !important;
    margin-top: calc($header-height + 35px) !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
  }
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 450px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }

  /*
  @include media("<small") {
    justify-content: center;
    padding-top: 5px;

  }
  @include media("<370px") {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  */
}

.header {
  text-align: center;
  font-weight: 500 !important;
  font-size: 22px !important;
}

.backdrop {
  backdrop-filter: blur(3px);
}

.title {
  padding: 32px !important;

  h2 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.titleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.button {
  width: 100%;
  margin-top: $space-l;
  display: inline-block;
  padding: $space-m;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  box-shadow: none;

  border: 0;
  border-radius: 53px;

  outline: none;
  user-select: none;
  cursor: pointer;

  transition: opacity 120ms, background-color 120ms;

  z-index: 0;

  color: white;
  background: $color-primary;

  &:hover {
    background-color: $color-primary-dark;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}
