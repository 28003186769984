@import '~styles/base.module.scss';

.component {
  background-color: #ffff;
  @media (min-height: 100px) {
    overflow: hidden;
  }
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  position: relative;
  width: 100%;
  padding-top: $header-height + $aux-header-height;
  padding-bottom: $space-xl;
  margin: $space-m auto $space-l auto;
  max-width: $view-max-width;
  padding-left: $space-m;
  padding-right: $space-m;

  @include media('<tablet') {
    padding-bottom: 12px;
  }
  @media screen and (max-width: 331px) {
    width: 100%;
    padding-left: 2px;
    padding-right: 2px;
    margin: 0;
  }
}

.footer {
}
