@import '~styles/base.module.scss';

.content {
  height: 100%;
  width: 100%;

  @include media('<tablet') {
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.closeModalArrow {
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.closeButtonPosition {
  width: 100%;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

.modalContainer {
  max-width: 560px !important;
  width: 560px !important;
  margin: auto;
  box-shadow: 2px;
  z-index: 2000;
  padding: $space-l !important;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: visible !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include media('<small') {
    padding: $space-m !important;
    width: 95% !important;
    overflow: auto;
  }
}

.modal {
  margin: $space-m;
  margin-top: 10px;
  margin-bottom: 4;
  text-align: center;

  @include media('<small') {
    height: auto !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  text-align: center;
  margin: 5%;
  margin-top: 0% !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}

.backdrop {
  backdrop-filter: blur(3px);
}

.title {
  padding: 32px !important;

  h2 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.titleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.button {
  width: 100%;
  margin-top: $space-l;
  display: inline-block;
  padding: $space-m;
  font: inherit;
  font-weight: 600;
  line-height: 1;
  box-shadow: none;

  border: 0;
  border-radius: 53px;

  outline: none;
  user-select: none;
  cursor: pointer;

  transition: opacity 120ms, background-color 120ms;

  z-index: 0;

  color: white;
  background: $color-primary;

  &:hover {
    background-color: $color-primary-dark;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.images {
  max-width: 75%;
  padding: $space-l $space-m 0 $space-m !important;

  @include media('<tablet') {
    max-width: 100%;
  }
}

.contentImage {
  display: flex;
  justify-content: center;
}

.indicatorWrapper {
  padding-top: $space-s;
  display: flex;
  justify-content: center;
  margin-left: auto !important;
  margin-right: 2px;

  @include media('<tablet') {
    padding-top: 0px;
  }
}

.informationButton {
  margin-top: $space-m !important;
  width: 65% !important;
  text-align: center;
  padding: 5px;
  text-transform: uppercase !important;
  font-size: 13px !important;
  border-radius: 32px !important;
  background-color: #ed1c24 !important;
  color: #ffffff !important;

  @include media('<tablet') {
    width: 85% !important;
  }
}

.infoButton {
  display: flex;
  justify-content: center;
  width: 100% !important;
  margin-bottom: 10px !important;
}
