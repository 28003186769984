@import '~styles/base.module.scss';

.component {
  $height: 47px;
  height: 47px !important;
  margin-bottom: 3px;
  border: 1px;
  border-radius: 78px !important;

  :global {
    .Select {
      align-items: center !important;
      border: none;
      max-height: 150px;
      border-radius: 15px !important;
    }

    .Select__control {
      font-size: 17px !important;
      color: $color-primary !important;
      font-family: inherit !important;
      font-weight: 400 !important;
      z-index: 0;

      height: $height;
      border: 1.5px solid $color-dark-gray;
      border-radius: 54px;

      outline: none !important;
      box-shadow: none !important;

      transition: border-color ease-in-out 120ms, box-shadow ease-in-out 120ms;

      .Select__input {
        height: $height;
        z-index: 0;
      }
    }

    // borde del select
    .Select__control--is-focused {
      border-bottom: none;
      border: 2px solid $color-primary !important;
    }

    .Select__indicators {
      span {
        display: none;
      }
    }

    .Select__indicator {
      cursor: pointer;
      color: grey;

      & {
        padding: 0 !important;
        margin-right: 13px !important;
        margin-bottom: 14px !important;
      }
    }

    .Select__value-container {
      align-items: center;
    }

    .Select__placeholder {
      color: $color-placeholder;
      padding-left: $space-xs;
      padding-right: $space-m;
      line-height: $height;
      font-size: 17px;
      vertical-align: center;
      top: 38%;
    }

    .Select--single > .Select__control .Select__value {
      line-height: $height;
    }

    .Select__input,
    .Select__value {
      padding-left: $space-xs !important;
      padding-right: $space-m !important;
    }

    .Select__input > input {
      line-height: $height - 20px;
    }

    .Select__arrow-zone {
      padding-right: $space-m;
    }

    .Select__arrow-zone:hover > .Select__arrow {
      border-top-color: $color-text !important;
    }

    .Select__arrow {
      border-top-color: $color-text !important;
      margin-top: 9px;
    }

    .Select__menu-list {
      max-height: 150px !important;
      font-size: 11px !important;
      color: inherit !important;
      font-family: inherit !important;
      font-weight: 400 !important;
      margin: 0 !important;
      padding: 0 !important;
      outline: 0;
      border-radius: 10px 5px 5px 10px;
      z-index: 9999;
      overflow: overlay;
      border: none;
      border-color: $color-primary !important;
      box-shadow: 30px black !important;
      color: black !important;
    }

    .Select__menu {
      max-height: 150px !important;
      margin: 0 !important;
      padding: 0;
      border-radius: 10px 5px 5px 10px !important;
      color: black !important;
      box-shadow: 30px black !important;
      background-color: $color-white !important;
      border: none;
      border-color: $color-primary !important;
      &:hover {
        cursor: default !important;
      }
    }

    .Select__single-value {
      padding-left: $space-xs !important;
      padding-right: $space-l !important;
      top: 38%;
      color: black !important;
      &:hover {
        cursor: default !important;
      }
    }

    .Select__option {
      padding: $space-m;
      color: black !important;
      background-color: $color-white !important;
      &:hover {
        // definicion del color del hover del select
        background-color: #fbd2d3 !important;
        cursor: default !important;
      }
    }

    .Select__option--is-selected {
      // cuando yá está seleccionada de antes
      color: white !important;
      background-color: #fbd2d3 !important;
      &:hover {
        background-color: #fbd2d3 !important;
      }
    }

    .Select__noresults {
      line-height: $height - 20px;
    }

    .is-focused {
      .Select__control {
        border-color: $color-primary !important;
      }
    }
  }
}

.disabled {
  :global {
    .Select {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .Select-control {
      pointer-events: none;
    }
  }
}

.label {
  font-weight: 600;
  margin-bottom: $space-s;
}

.helper {
  margin-top: $space-s;
  margin-bottom: $space-m;
  color: $color-placeholder;
}

.required {
  .label {
    &:after {
      display: inline;
      content: '*';
      margin-left: 2px;
    }
  }
}

.error {
  margin-top: 4px;

  small {
    color: $color-red;
  }
}

.option {
  line-height: 28px;
}

:global(.Select-value) {
  .option {
    line-height: 48px;
  }
}

/*SCROLLBAR*/

/* width */
::-webkit-scrollbar {
  width: 5px;
  border: none;
  overflow: overlay !important;
  border-radius: 15px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;

  border-radius: 30px !important;
  background-color: $color-white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  margin-right: 5px !important;
  background: rgb(133, 131, 131);
  border: 4px solid rgb(133, 131, 131);
  border-radius: 15px;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-corner {
  border-radius: 20px !important;
}
