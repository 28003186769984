@import '~styles/base.module.scss';

div[aria-hidden='true'] {
}

/* MODAL */
.modalContainer {
  max-width: 512px !important;
  width: 512px !important;
  flex-basis: 35em;

  max-height: 100%;
  margin: auto;
  margin-top: 0;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;

  @include media('<small') {
    position: c center;
    width: 100% !important;
    margin-top: calc($header-height + 35px) !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    padding: 20px !important;
    backdrop-filter: none !important;
  }
}

.modal {
  margin: 75px;
  margin-top: 10px;
  margin-bottom: 15px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;

  @include media('<small') {
    margin: 0% 5% 4% 2%;
  }
}

.labelWrapperPublic {
  display: flex;
  flex-direction: row wrap;
}

.nav {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  padding-inline: 10px;
  height: 7%;
  padding-bottom: 17px;
  padding-top: 10px;

  @include media('<small') {
    margin-bottom: 10px !important;
  }
}

.subheaderDeviceSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}

.subheaderEnviroment {
  padding: 4px;
  font-size: 12px;
}

.subheaderDevice {
  padding: 4px;
  font-size: 12px;
}

.subheaderEnviromentSelected {
  border: 1px solid $color-dark-gray;
  border-radius: 30px;
  padding: 4px;
  font-size: 12px;
}

.infoPublicWrapper {
  padding-top: 4% !important;
  display: flex;
  font-size: 12px !important;

  @include media('<small') {
    padding-bottom: 0 !important;
  }
}

.iconPublic {
  padding-right: 5%;
}

.buildingTypeInfoLabel {
  font-size: 12px !important;
}

.input label span {
  display: none;
}

.input {
  margin-top: 1.05em !important;
  margin-bottom: 0.25em !important;
  position: relative;
  width: 100%;
  padding: 0.3%;

  input {
    padding: 18.5px 14px !important;
  }

  align-items: center;
  color: $color-primary !important;

  label {
    margin-left: 3px !important;
  }
}

.input label span {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-ms-autofill,
input:-ms-autofill:hover,
input:-ms-autofill:focus,
textarea:-ms-autofill,
textarea:-ms-autofill:hover,
textarea:-ms-autofill:focus,
select:-ms-autofill,
select:-ms-autofill:hover,
select:-ms-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-o-autofill,
input:-o-autofill:hover,
input:-o-autofill:focus,
textarea:-o-autofill,
textarea:-o-autofill:hover,
textarea:-o-autofill:focus,
select:-o-autofill,
select:-o-autofill:hover,
select:-o-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
textarea:-moz-autofill,
textarea:-moz-autofill:hover,
textarea:-moz-autofill:focus,
select:-moz-autofill,
select:-moz-autofill:hover,
select:-moz-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0 #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.cselect {
  margin-top: 1.05em !important;
  margin-bottom: 0 !important;

  :hover {
    cursor: text !important;
  }
}

.moreInfoWrapper {
  text-align: center;
  max-height: 25px !important;
}

.moreInfoButton {
  border-radius: 64px;
  border-color: transparent;

  background-color: $color-white;
  color: $color-primary;

  font-size: 12px !important;
  padding: $space-s $space-m;
  margin-left: auto;
  margin-right: auto;

  text-transform: capitalize;

  &:disabled {
    border: 0 !important;
    pointer-events: auto !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
  }

  &:hover {
    background-color: $color-white;
  }

  @include media('<small') {
    display: none;
  }
}

.caja {
  background-color: $color-white;
  border-radius: 30px;
  z-index: 7000 !important;
  position: relative;
  padding: 10%;
  max-width: 500px;
  box-shadow: 0 0 5px grey !important;
}

.cajaHeader {
  position: absolute;
  top: $space-m;
  right: $space-m;
}

.titleIconClose {
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.custom {
  margin-bottom: 0 !important;
}

.moreInfoWrapper {
  text-align: center;
  max-height: 25px !important;
}

.moreInfoButton {
  border-radius: 64px;
  border-color: transparent;

  background-color: $color-white;
  color: $color-primary;

  font-size: 12px !important;
  padding: $space-s $space-m;
  margin-left: auto;
  margin-right: auto;

  text-transform: capitalize;

  &:disabled {
    border: 0 !important;
    pointer-events: auto !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
  }

  &:hover {
    background-color: $color-white;
  }

  @include media('<small') {
    display: none;
  }
}

textarea {
  font-weight: 100;
  background-color: white;
  background-clip: padding-box;
  min-height: 47px;
  line-height: 40px;
}

.required {
  &:after {
    display: inline;
    color: $color-red;
    content: '*';
    margin-left: 2px;
  }
}

.hidden {
  display: none;
}

/*BUTTONS*/
.buttonWrapper {
  text-align: center;
  margin: 0;
}

.buttonWrapperF {
  margin-top: 30px !important;
  text-align: center;
  margin: 0;
}

.buttonSiguiente {
  margin-top: 25px;
  border-radius: 78px !important;
  background-color: $color-primary;
  color: $color-white;
  align-items: center;
  margin-bottom: 0;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }
}

.buttonAdd {
  // diferenciamos para poner más margen para que no varíe el tamaño del modal
  margin-top: 65px !important;
  border-radius: 78px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:disabled {
    background-color: $color-extra-extra-light-gray !important;
    color: $color-extra-light-gray !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none !important;
    border: none !important;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0.35em;
    margin-right: 0;
  }
}

.buttonCancelar {
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 78px;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }

  &:focus {
    border-color: transparent !important;
    outline: none !important;
    border: none !important;
  }

  .arrow {
    height: 0.7em !important;
    margin-left: 0;
    margin-right: 0.35em;
  }
}

/* HELPERS SUBMESAGES TEXTFIELDS */
.helper {
  color: #9d9d9d;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  margin-top: 0 !important;
}

/* PUBLIC WRAPPER */

.publicContainer {
  display: inline;
}

/* CHECKBOX */
.checkboxFormDiv {
  padding-top: 0;
  display: inline-block !important;
  align-items: flex-start;
  padding: 20px 0;
}

.checkboxForm {
  display: flex;
  align-items: flex-start;
  margin: 0;

  span {
    font-size: 14px !important;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkedBox {
  svg {
    color: #ff3152 !important;
    margin: 0;
    padding: 0;
  }
}

.checkbox {
  //recuadro del check
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: #fafafa;
  text-align: center;

  svg {
    color: black;
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #ff3152 !important;
    background-color: $color-primary !important;
  }
}

.checkboxIcon {
  //el tick
  background-color: $color-white !important;
  color: $color-primary !important;
  border-radius: 2px;
}

.shareableLink {
  margin-top: $space-s;
}

/*ERROR MESSAGES*/
.hasError {
  border-color: $color-red !important;
}

.error {
  color: $color-error !important;
  margin: 0 !important;
}

.errorMessage {
  color: $color-error !important;
  margin: $space-l 0 0 0 !important;
  background-color: rgba($color-error, 0.2) !important;
}

.errorId {
  display: block;
  text-align: left;
}
