@import '~styles/base.module.scss';

.component {
  position: fixed;
  top: $aux-header-height;
  right: 0;
  left: 0;
  display: block;
  height: $header-height;
  color: black !important;
  box-shadow: 0 0 0 0 !important;
  background-color: $color-white;

  @include media('<tablet') {
    top: 0;
    background-color: $color-primary !important;
    height: 90px;
  }
}

.content {
  position: relative;
  padding: 0 $space-m;
  margin: 0 auto;
  max-width: $view-max-width;
  min-height: 100%;

  display: flex;
  align-items: center;
  height: 100%;
}

.logoWrapper {
  height: 100%;
  width: auto;
  padding: $space-s;
  margin: 0;

  @include media('<tablet') {
    display: none;
  }
}

.userOptionsLogo {
  margin: 3%;
}

.userOptionsText {
  color: rgba(0, 0, 0, 0.8);
  padding: 5%;
}

.logoMobile {
  height: 55px;
  width: 55px;

  cursor: pointer;
}

.logo {
  height: 100%;

  cursor: pointer;
}

.logoImageMobile {
  height: 95px;
  width: 95px;
}

.logoImage {
  height: 100%;
}

.nav {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  align-items: center;

  @include media('<tablet') {
    display: none;
  }
}

.link {
  display: inline;
}

.navItem {
  margin-right: $space-l;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-color ease 0.3s;
  color: inherit;
  border-radius: 81px;

  padding: 3px $space-m !important;

  &:hover {
    padding-bottom: 3px;
    text-decoration: none;
    border-color: white;
    background-color: rgba($color-primary, 0.4);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<tablet') {
    margin-right: $space-s;
    min-width: 48px;
    right: 0;
  }
}

.menuItem {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Roboto';

  transition: color 0.3s ease;
  cursor: pointer;
  color: $color-text;
  width: 100%;
  background-color: none;

  &:hover {
    color: $color-primary;
    background-color: inherit;
  }
}

.menuItemSelected {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Roboto';
  color: $color-primary !important;
  transition: color 0.3s ease;
  cursor: pointer;
  width: 100%;
  background-color: $color-primary;

  &:hover {
    background-color: #fbd2d3 !important;
    background-color: inherit;
  }
}

.buttonLanguages {
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-size: 14px;
  padding: 0;
  border-radius: 0;
  z-index: 0;
  color: white;
  text-transform: none;

  &:hover {
    background: transparent;
    border-radius: 0;
    padding-bottom: 0;
  }
}

.mobileNav {
  display: none;

  @include media('<tablet') {
    display: flex;
    flex-flow: row nowrap;
  }
}

.mobileLogo {
  display: none;

  @include media('<tablet') {
    display: block;
    height: 100%;
    width: 100%;
    text-align: center;
  }
}

.mobileMenuIcon {
  color: white;
}

.mobileMenu {
  position: fixed;
  left: 0;
  top: calc($header-height + 18px);
  right: 0;
}

.mobileMenuOpen {
  height: 100%;
}

.mobileMenuContent {
  width: 100%;
  height: 100%;
  background-color: $color-primary;
  padding: $space-l 0;
  color: white;
  text-align: center;
}

.mobileMenuContentClose {
  display: none;
}

.mobileMenuDivider {
  margin-top: $space-l;
  width: 30%;
  border-color: white;
}

.discoverInbiot {
  color: white;

  padding: 5% 0% 3% 0%;
  font-size: 18px;

  align-items: center;
}

.mobileNavItem {
  display: block;
  width: 100%;
  padding: $space-s 0;
  cursor: pointer;
  transition: color ease 0.3s;
  color: $color-white;
  font-size: 22px;

  &:hover {
    font-size: 26px;
  }
}

.helpButtonText {
  font-size: 18px;
}

.helpMobileNavItem {
  display: block;
  width: 100%;
  padding: $space-m 0;
  cursor: pointer;
  transition: color ease 0.3s;
  color: rgba(255, 255, 255, 1);
  font-size: 22px;

  &:hover {
    font-size: 26px;
  }
}

.activeMobileNavItem {
  text-decoration: underline;
  text-decoration-color: $color-white;
  text-underline-offset: 3px;
}

.disabledMobileNavItem {
  color: $color-light-gray;
}

.activeNavItem {
  border: 1px solid $color-primary;
  color: $color-primary;
}

.disabledNavItem {
  color: $color-light-gray !important;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-primary;
  cursor: pointer;
}

.menuLanguagePaper {
  border: 1px solid #dddddd;
  border-radius: 15px;
  padding: 0;
  margin-top: 10px;
  transition: color 0.3s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  width: 100px;
}

.languageMenuWrapper {
  position: absolute;
  right: 0;
}

.menuHelpPaper {
  border: 1px solid #dddddd;
  border-radius: 15px;
  padding: 0;
  margin-top: 10px;
  transition: color 0.3s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  width: 200px;
}

.userMenuWrapper {
  position: absolute;
  right: 0;
}

.menuUserPaper {
  border: 1px solid #dddddd;
  border-radius: 15px;
  padding: 0;
  margin-top: 40px;
  transition: color 0.3s ease;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  width: 200px;
}

.userIcon {
  color: white;
}
