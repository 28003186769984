@import '~styles/base.module.scss';

.backdrop {
  backdrop-filter: blur(50px) !important;

  @include media('<small') {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
}

.flexModalContainer {
  display: flex;
  height: 100%;
}

.modalContainer {
  max-width: 323px !important;
  width: 323px !important;
  flex-basis: 35em;
  margin: auto;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  @include media('<small') {
    position: center;
    max-width: 100% !important;
    width: 100% !important;
    margin-top: calc($header-height + 35px) !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    backdrop-filter: none !important;
  }
}

.content {
  height: 100% !important;
  padding-top: $space-m !important;
  padding-bottom: $space-l !important;
  width: 85%;
  margin: 0 auto;
}

.modal {
  margin: 75px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
    padding: 30px !important;
  }
}

.header {
  margin: 6% 3% 0% 5%;
  font-weight: 500 !important;
  font-size: 22px !important;
  font-weight: bold;
  font-size: 1.4em;

  @include media('<small') {
    margin: 3% 5% 4% 5%;
  }
}

.buttonCancel {
  float: left;
  padding: $space-s $space-m;

  border-radius: 38px;
  border-color: transparent;

  background-color: $color-white;
  color: $color-primary;

  margin-bottom: $space-m;
  margin-right: 8px;

  &:hover {
    background-color: rgba($color-primary, 0.2);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.buttonConfirm {
  float: right;
  border-radius: 38px;
  border-color: transparent;

  background-color: $color-error;
  color: $color-white;

  padding: $space-s $space-m;
  margin-bottom: $space-m;
  margin-right: 8px;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.title {
  padding: 0px !important;
  margin: 24px 184px 0px 70px !important;
  font-weight: bold;

  h2 {
    font-size: 21.33px !important;
    font-weight: 500 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.titleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.contentText {
  padding: 10px 0px 35px 0px;
}

.contentDetail {
  padding: $space-m $space-s $space-s 0;
  text-align: center;
}
