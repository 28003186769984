@import '~styles/base.module.scss';

.modal {
  @include media('<tablet') {
    //display: none !important;
    background-color: blue;
  }
}

#AddSystemModalForm {
  @include media('>tablet') {
    //display: none !important;
    background-color: blue;
  }
}

.mobileForm {
  @include media('>tablet') {
    display: none !important;

    background-color: blue;
  }
}

.backdrop {
  backdrop-filter: blur(50px) !important;
  @include media('<tablet') {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
}

.content {
  height: 100% !important;
  border-top: 1px solid $color-border;
  background-color: darken(white, 3%);
  @include media('<tablet') {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
}

.title {
  padding: 0px !important;
  margin: 24px 184px 0px 70px !important;
  font-weight: bold;

  h2 {
    font-size: 21.33px !important;
    font-weight: 500 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.titleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
}
