@import '~styles/base.module.scss';

.modalContainer {
  max-width: 500px !important;
  width: 500px !important;

  max-height: 100% !important;
  margin: auto;
  box-shadow: 2px;
  padding: 1px 4px;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;
  flex-basis: 35em;

  overflow-y: auto !important;

  @include media('<small') {
    position: center;
    width: 100% !important;
    margin-top: calc($header-height + 35px) !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
  }
}

.modal {
  margin: 65px;
  margin-top: 10px;
  margin-bottom: 5px;

  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
}

.errorWrapper {
  margin-top: $space-m !important;
  margin-bottom: $space-m !important;
}

/*BUTTONS*/

/*BUTTONS*/
.buttons {
  @include media('<small') {
    margin-top: 0 !important;
  }
}

.buttonWrapper {
  text-align: center;
  margin: 0;
  font-weight: 600px !important;
}

.buttonSiguiente {
  margin-top: 30px;
  border-radius: 30px;
  background-color: $color-primary;
  color: $color-white;
  box-shadow: 3px 4px 3px rgb(168, 167, 167) !important;
  align-items: center;
  margin-bottom: 0;

  &:hover {
    background-color: #006db3;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .arrow {
    margin-left: 0.35em !important;
    margin-right: 0 !important;
  }
}

.buttonAdd {
  margin-top: 30px !important;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }

  &:disabled {
    background-color: $color-extra-extra-light-gray !important;
    color: $color-extra-light-gray !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  .buttonAdd .arrow {
    margin-left: 0.35em !important;
    margin-right: 0 !important;
  }
}

.buttonCancelar {
  margin-top: 10px;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  font-weight: bolder !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;

  &:hover {
    background-color: rgb(198, 230, 248);
  }

  .arrow {
    margin-right: 0.35em;
    margin-left: 0;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.visualizeQRWrapper {
  display: flex;
  font-family: bold !important;
  align-items: center;
  justify-content: space-between;
}

.infoPublicWrapper {
  padding-top: 4% !important;
  display: flex;
  padding-bottom: 10%;
  font-size: 12px !important;

  @include media('<small') {
    padding-bottom: 0 !important;
  }
}

.infoUpdateWrapper {
  display: flex;
  font-size: 12px !important;

  @include media('<small') {
    padding-bottom: 0 !important;
  }
}

.iconPublic {
  padding-right: 5%;
}

.menuItem {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Roboto';
  padding-top: 7px;
  padding-bottom: 7px;

  transition: color 0.3s ease;
  cursor: pointer;
  color: $color-text;
  width: 100%;

  &:hover {
    background-color: rgba(0, 155, 229, 0.2) !important;
  }

  &:active {
    background-color: rgba(0, 155, 229, 0.5) !important;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.menuItemFocus {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  font-family: 'Roboto';
  padding-top: 7px;
  padding-bottom: 7px;

  transition: color 0.3s ease;
  cursor: pointer;
  color: $color-text;
  width: 100%;
  background-color: #fbd2d3;

  &:hover {
    background-color: #fbd2d3 !important;
  }
}

.wrapperDefaultValues {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkboxList {
  padding-bottom: 50% !important;
}

.checkboxWrapper {
  height: 435px !important;
  max-height: 435px !important;
  overflow: auto;
  padding: 7%;
  padding-top: 2%;
  flex-flow: column wrap;
}

.paramWrapper {
  height: 435px !important;
  max-height: 435px !important;
  padding: 7% 11% 0 0;
  flex-flow: column wrap;
}

.checkboxFormDiv {
  align-items: center;
  justify-content: space-between;
}

.dataInput {
  justify-content: flex-end;
}

.unitsParams {
  text-align: end !important;
  font-size: 0.75rem !important;
  font-family: sans-serif !important;

  .MuiTypography-root {
    font-size: 0.75rem !important;
    font-family: sans-serif !important;
  }

  .MuiInputAdornment-positionEnd {
    font-size: 0.75rem !important;
    font-family: sans-serif !important;
  }
}

.MuiTypography-root {
  font-size: 0.75rem !important;
  text-align: end !important;
  font-family: sans-serif !important;
}

MuiInputAdornment-positionEnd {
  font-size: 0.75rem !important;
  font-family: sans-serif !important;
}

.inputUnit {
  display: inline-block;
  font-weight: 450;
  width: 100%;
  text-align: right !important;

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.paramFormDiv {
  padding-top: 0;
  display: flex;
  align-items: center;
  max-height: 45px !important;
  padding: none;
}

.wrapperDataInfo {
  display: flex !important;
  align-content: baseline !important;
  align-items: center;
  margin-left: auto;
  justify-content: space-between;
}

.checkboxForm {
  padding-top: 15px;
  display: flex;
  align-items: center;
  max-height: 45px !important;
  justify-content: space-between;
  padding: none;
  margin: 0;

  span {
    font-size: 14px !important;
    line-height: 1.5;
    padding: 0px;
    border-radius: 4px;
  }
}

.checkedBox {
  svg {
    color: #ff3152 !important;
    margin: 0;
    padding: 0;
  }
}

.checkbox {
  //recuadro del check
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  background-color: $color-white !important;
  text-align: center;

  svg {
    color: black;
  }

  .inputParam {
    background-color: rebeccapurple;
    text-align: end !important;
    font-weight: 800px !important;

    &:hover {
      background-color: $color-primary !important;
    }
  }

  &:hover {
    background-color: white !important;
  }

  &:checked {
    color: #ff3152 !important;
    background-color: $color-primary !important;
  }
}

.checkboxIcon {
  //el tick
  background-color: $color-white !important;
  color: $color-primary !important;
  border-radius: 2px;
}

.hasError {
  border-color: $color-error !important;
}

.checkboxIcon {
  background-color: $color-primary;
  color: $color-white !important;
  border-radius: 4px;
}

.radioButtonRow {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 5% !important;
}

.radioButton {
  color: $color-primary !important;
  width: 15%;
}

.radioButtonLabel {
  width: 70%;
  border-radius: 65px !important;
  border: 2px solid $color-primary !important;
  padding: 2% 0% 2% 2% !important;
}

.openScreenButton {
  color: $color-white !important;
  background-color: $color-primary !important;
  border-radius: 38px;
  float: right;
  margin-top: 5%;
}

.error {
  margin-top: $space-s !important;
  color: $color-red !important;
  margin-bottom: $space-s !important;
}

.wrapperInfoButton {
  display: flex;
  flex-flow: row nowrap;
  float: right;
  border-radius: 38px;
  border-color: transparent;
  padding-left: 10px;
  text-transform: unset !important;
  background-color: $color-white;
  color: $color-black;

  &:hover {
    background-color: $color-extra-extra-light-gray;
    opacity: 80%;
  }

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }

  @include media('<small') {
    font-size: 0;
  }
}

.infoButton {
  padding: 0 !important;

  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}

.dialogInfo {
  border-radius: 50px;
}

.caja {
  background-color: $color-white;
  border-radius: 30px;
  z-index: 7000 !important;
  position: relative;
  left: 360px;
  bottom: 70px;
  padding: 5%;
  box-shadow: 0 0 5px grey !important;
}

.wrap {
  max-height: 45px !important;
}

.inputUnity {
  max-width: 2px;
  padding: 7px;
  margin-left: auto;
  font-size: '0.85rem';
  color: grey;
}

.errorMessage {
  color: $color-error !important;
  margin: 10px 0 0 0 !important;
  background-color: rgba($color-error, 0.2) !important;
}
