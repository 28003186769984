@import '~styles/base.module.scss';

.component {
  height: auto;
}

.content {
  background-color: #ffffff;
  position: relative;
  width: 100%;
  margin: $space-m auto $space-l auto;
  padding-top: $header-height + $aux-header-height;
  padding-bottom: $space-xxl;
  padding-left: $space-m;
  padding-right: $space-m;

  @include media('<tablet') {
    padding-bottom: 12px;
  }
}

.withColor {
  padding: $space-m;
  background-color: $color-extra-extra-light-gray;
  border: 1px solid $color-border;
}

.footer {
}
