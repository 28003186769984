@import '~styles/base.module.scss';

.modal {
  @include media('<small') {
    background-color: blue;
  }
}

#AddSystemModalForm {
  @include media('>small') {
    background-color: blue;
  }
}

.mobileForm {
  @include media('>small') {
    display: none !important;

    background-color: blue;
  }
}

.content {
  display: flex;
  height: 100% !important;
  width: 100%;

  @include media('<small') {
    backdrop-filter: none !important;
    background-color: transparent !important;
  }
}

.title {
  padding: 32px !important;

  h2 {
    font-size: 24px !important;
    font-weight: 600 !important;
  }

  button {
    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.titleIconClose {
  position: absolute;
  right: 0;
  top: 8px;
  color: $color-dark-gray;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
}
